var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("管理层")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Leadership")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("管理層")]) : _vm._e()]), _c("div", {
    staticClass: "companyInfo clearfix"
  }, [_c("ul", [_vm._l(_vm.managementList, function (items, index) {
    return _vm.language == 1 ? _c("li", [_c("div", {
      staticClass: "leftCon"
    }, [_c("img", {
      attrs: {
        src: _vm.title + items.headImage
      }
    })]), _c("div", {
      staticClass: "rightCon"
    }, [_c("p", {
      staticClass: "name"
    }, [_vm._v(_vm._s(items.cnName))]), _c("p", {
      staticClass: "position"
    }, [_vm._v(_vm._s(items.cnPosition))]), _c("p", {
      staticClass: "introduce"
    }, [_vm._v(_vm._s(items.cnIntroduction))])])]) : _vm._e();
  }), _vm._l(_vm.managementList, function (items, index) {
    return _vm.language == 2 ? _c("li", [_c("div", {
      staticClass: "leftCon"
    }, [_c("img", {
      attrs: {
        src: _vm.title + items.headImage
      }
    })]), _c("div", {
      staticClass: "rightCon"
    }, [_c("p", {
      staticClass: "name"
    }, [_vm._v(_vm._s(items.enName))]), _c("p", {
      staticClass: "position"
    }, [_vm._v(_vm._s(items.enPosition))]), _c("p", {
      staticClass: "introduce"
    }, [_vm._v(_vm._s(items.enIntroduction))])])]) : _vm._e();
  }), _vm._l(_vm.managementList, function (items, index) {
    return _vm.language == 3 ? _c("li", [_c("div", {
      staticClass: "leftCon"
    }, [_c("img", {
      attrs: {
        src: _vm.title + items.headImage
      }
    })]), _c("div", {
      staticClass: "rightCon"
    }, [_c("p", {
      staticClass: "name"
    }, [_vm._v(_vm._s(items.trName))]), _c("p", {
      staticClass: "position"
    }, [_vm._v(_vm._s(items.trPosition))]), _c("p", {
      staticClass: "introduce"
    }, [_vm._v(_vm._s(items.trIntroduction))])])]) : _vm._e();
  })], 2)])])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/management.jpg")
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };